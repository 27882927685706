import React from "react";
import Layout from "../components/layout";
import banner from "../images/projects/spot/spot-club-banner.jpg";
import logo_telegram from "../images/blog/telegram.svg";
import logo_vk from "../images/blog/vk.svg";
import logo_facebook from "../images/blog/facebook.svg";

const BlogPage = () => {
    return (
        <Layout>
            <div className="blog-header">
                <div className="blog-header-img">
                    <img src={banner} alt="#" />
                </div>
                <div className="blog-header-title intro-title">
                    7 правил создания {"\n"}красивых интерфейсов
                </div>
            </div>
            <div className={"blog-page-container"}>
                <div className="blog-page-date">27 июня 2019</div>
                <div className="blog-page-block">
                    <div className="blog-page-title">Свет падает сверху</div>
                    <div className="blog-page-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut volutpat, justo nec cursus finibus, dolor libero
                        mattis velit, id pulvinar odio sem et erat. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Aliquam
                        porttitor sed metus aliquet efficitur. Vestibulum et
                        justo ac justo scelerisque feugiat. Ut molestie aliquam
                        ligula, vel commodo lacus posuere eu. Praesent vel
                        turpis auctor, dapibus urna vel, fringilla turpis. In
                        congue pharetra felis, at pretium erat cursus eget.
                        Suspendisse velit nisi, ultricies et feugiat volutpat,
                        congue vel justo.
                    </div>
                    <div className="blog-page-img">
                        <img src={banner} alt="#" />
                    </div>
                    <div className="blog-page-img-description">
                        Иллюстрация из руководства Material design
                    </div>
                </div>
                <div className="blog-page-block">
                    <div className="blog-page-title">Свет падает сверху</div>
                    <div className="blog-page-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut volutpat, justo nec cursus finibus, dolor libero
                        mattis velit, id pulvinar odio sem et erat. Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Aliquam
                        porttitor sed metus aliquet efficitur. Vestibulum et
                        justo ac justo scelerisque feugiat. Ut molestie aliquam
                        ligula, vel commodo lacus posuere eu. Praesent vel
                        turpis auctor, dapibus urna vel, fringilla turpis. In
                        congue pharetra felis, at pretium erat cursus eget.
                        Suspendisse velit nisi, ultricies et feugiat volutpat,
                        congue vel justo.
                    </div>
                    <div className="blog-page-img">
                        <img src={banner} alt="#" />
                    </div>
                    <div className="blog-page-img-description">
                        Иллюстрация из руководства Material design
                    </div>
                </div>
            </div>
            <div className={"blog-page-container blog-page-footer"}>
                <div className="blog-page-footer-text">Поделиться:</div>
                <div className="blog-page-footer-icons">
                    <ul>
                        <li>
                            <a
                                href="https://vk.com/growire"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="blog-page-footer-icon">
                                    <img
                                        id="vk-logo"
                                        src={logo_vk}
                                        alt="vk logo"
                                    />
                                </i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.facebook.com/growire"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="blog-page-footer-icon">
                                    <img
                                        src={logo_facebook}
                                        alt="facebook logo"
                                    />
                                </i>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="blog-page-footer-icon">
                                    <img
                                        src={logo_telegram}
                                        alt="telegram logo"
                                    />
                                </i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout>
    );
};

export default BlogPage;
